.bg-slideshow {
  list-style: none;
  padding: 0;
  margin: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: -1;

  &:after {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    z-index: -1;
    content: '';
    background-color: rgba(6, 32, 49, 0.9);
    // background: transparent url(../images/pattern.png) repeat top left;
  }

  li {
    span {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      color: transparent;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      opacity: 0;
      z-index: -2;
      -webkit-backface-visibility: hidden;
      -webkit-animation: imageAnimation 24s linear infinite 0s;
      -moz-animation: imageAnimation 24s linear infinite 0s;
      -o-animation: imageAnimation 24s linear infinite 0s;
      -ms-animation: imageAnimation 24s linear infinite 0s;
      animation: imageAnimation 24s linear infinite 0s;
    }

    &:nth-child(1) span {
      background-image: url(../../images/1.webp);
    }

    &:nth-child(2) span {
      background-image: url(../../images/2.webp);
      -webkit-animation-delay: 6s;
      -moz-animation-delay: 6s;
      -o-animation-delay: 6s;
      -ms-animation-delay: 6s;
      animation-delay: 6s;
    }

    &:nth-child(3) span {
      background-image: url(../../images/3.webp);
      -webkit-animation-delay: 12s;
      -moz-animation-delay: 12s;
      -o-animation-delay: 12s;
      -ms-animation-delay: 12s;
      animation-delay: 12s;
    }

    &:nth-child(4) span {
      background-image: url(../../images/4.webp);
      -webkit-animation-delay: 18s;
      -moz-animation-delay: 18s;
      -o-animation-delay: 18s;
      -ms-animation-delay: 18s;
      animation-delay: 18s;
    }
  }
}

.txt-slideshow {
  list-style: none;

  li {
    div {
      z-index: 1000;
      position: absolute;
      left: 0px;
      width: 100%;
      text-align: center;
      opacity: 0;
      -webkit-animation: titleAnimation 24s linear infinite 0s;
      -moz-animation: titleAnimation 24s linear infinite 0s;
      -o-animation: titleAnimation 24s linear infinite 0s;
      -ms-animation: titleAnimation 24s linear infinite 0s;
      animation: titleAnimation 24s linear infinite 0s;

      p {
        font-family: 'BebasNeueRegular', 'Arial Narrow', Arial, sans-serif;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.9);
      }
    }


    &:nth-child(2) div {
      -webkit-animation-delay: 6s;
      -moz-animation-delay: 6s;
      -o-animation-delay: 6s;
      -ms-animation-delay: 6s;
      animation-delay: 6s;
    }

    &:nth-child(3) div {
      -webkit-animation-delay: 12s;
      -moz-animation-delay: 12s;
      -o-animation-delay: 12s;
      -ms-animation-delay: 12s;
      animation-delay: 12s;
    }

    &:nth-child(4) div {
      -webkit-animation-delay: 18s;
      -moz-animation-delay: 18s;
      -o-animation-delay: 18s;
      -ms-animation-delay: 18s;
      animation-delay: 18s;
    }

  }
}

@-webkit-keyframes imageAnimation {
  0% {
    opacity: 0;
    -webkit-animation-timing-function: ease-in;
  }

  12.5% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    -webkit-animation-timing-function: ease-out;
  }

  25% {
    opacity: 1;
    -webkit-transform: scale(1.1) rotate(3deg);
  }

  45% {
    opacity: 0;
    -webkit-transform: scale(1.1) rotate(3deg);
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes imageAnimation {
  0% {
    opacity: 0;
    -moz-animation-timing-function: ease-in;
  }

  12.5% {
    opacity: 1;
    -moz-transform: scale(1.05);
    -moz-animation-timing-function: ease-out;
  }

  25% {
    opacity: 1;
    -moz-transform: scale(1.1) rotate(3deg);
  }

  45% {
    opacity: 0;
    -moz-transform: scale(1.1) rotate(3deg);
  }

  100% {
    opacity: 0;
  }
}

@-o-keyframes imageAnimation {
  0% {
    opacity: 0;
    -o-animation-timing-function: ease-in;
  }

  12.5% {
    opacity: 1;
    -o-transform: scale(1.05);
    -o-animation-timing-function: ease-out;
  }

  25% {
    opacity: 1;
    -o-transform: scale(1.1) rotate(3deg);
  }

  45% {
    opacity: 0;
    -o-transform: scale(1.1) rotate(3deg);
  }

  100% {
    opacity: 0;
  }
}

@-ms-keyframes imageAnimation {
  0% {
    opacity: 0;
    -ms-animation-timing-function: ease-in;
  }

  12.5% {
    opacity: 1;
    -ms-transform: scale(1.05);
    -ms-animation-timing-function: ease-out;
  }

  25% {
    opacity: 1;
    -ms-transform: scale(1.1) rotate(3deg);
  }

  45% {
    opacity: 0;
    -ms-transform: scale(1.1) rotate(3deg);
  }

  100% {
    opacity: 0;
  }
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }

  12% {
    opacity: 1;
    transform: scale(1.05);
    animation-timing-function: ease-out;
  }

  25% {
    opacity: 1;
    transform: scale(1.1) rotate(3deg);
  }

  45% {
    opacity: 0;
    transform: scale(1.1) rotate(3deg);
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes titleAnimation {
  0% {
    opacity: 0;
    -webkit-transform: translateX(200px);
  }

  12% {
    opacity: 1;
    -webkit-transform: translateX(0px);
  }

  17% {
    opacity: 1;
    -webkit-transform: translateX(0px);
  }

  26% {
    opacity: 0;
    -webkit-transform: translateX(-400px);
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes titleAnimation {
  0% {
    opacity: 0;
    -moz-transform: translateX(200px);
  }

  12% {
    opacity: 1;
    -moz-transform: translateX(0px);
  }

  17% {
    opacity: 1;
    -moz-transform: translateX(0px);
  }

  26% {
    opacity: 0;
    -moz-transform: translateX(-400px);
  }

  100% {
    opacity: 0;
  }

}

@-o-keyframes titleAnimation {
  0% {
    opacity: 0;
    -o-transform: translateX(200px);
  }

  12% {
    opacity: 1;
    -o-transform: translateX(0px);
  }

  17% {
    opacity: 1;
    -o-transform: translateX(0px);
  }

  26% {
    opacity: 0;
    -o-transform: translateX(-400px);
  }

  100% {
    opacity: 0;
  }

}

@-ms-keyframes titleAnimation {
  0% {
    opacity: 0;
    -ms-transform: translateX(200px);
  }

  12% {
    opacity: 1;
    -ms-transform: translateX(0px);
  }

  17% {
    opacity: 1;
    -ms-transform: translateX(0px);
  }

  26% {
    opacity: 0;
    -ms-transform: translateX(-400px);
  }

  100% {
    opacity: 0;
  }

}

@keyframes titleAnimation {
  0% {
    opacity: 0;
    transform: translateX(200px);
  }

  12% {
    opacity: 1;
    transform: translateX(0px);
  }

  17% {
    opacity: 1;
    transform: translateX(0px);
  }

  26% {
    opacity: 0;
    transform: translateX(-400px);
  }

  100% {
    opacity: 0
  }

}

/* Show at least something when animations not supported */

.no-cssanimations .bg-slideshow li span {
  opacity: 1;
}


// ==========================================================================


.head_line {
  font-size: 2.5rem;
  text-align: center;
  line-height: 1.1;
}


// ==========================================================================


.scroll-down {
  font-size: 10px;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mouse {
  width: 1.25rem;
  height: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 2rem;
  margin-bottom: 0.5rem;
  display: flex;
}

.mouse span {
  width: 0.1rem;
  height: 0.3rem;
  background-color: #fff;
  display: block;
  border-radius: 50%;
  margin: auto;
  animation: move-wheel 1s linear infinite;
}

.arrow span {
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  border: 2px solid transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  transform: rotate(45deg);
  animation: arrow-down 1s alternate infinite;
}

@keyframes move-wheel {
  0% {
    opacity: 1;
    transform: translateY(-0.6rem);
  }

  100% {
    opacity: 0;
    transform: translateY(0.18rem);
  }
}

@keyframes arrow-down {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
}

// ==========================================================================
.folio {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.folio .f_shot {
  position: relative;
}

.f_shot img {
  border-radius: 0.25rem;
  width: 100%;
}

.f_shot .f_overlay {
  background-color: rgba(6, 32, 49, 0.6);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  transition: 0.5s ease;
}

.f_shot .f_overlay:hover {
  background-color: transparent;
  transition: 0.5s ease;
}

.f_overlay a {
  display: none;
}

.f_overlay:hover>a {
  display: block;
  font-size: 2.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease;
}

.f_desc {
  position: relative;
}

.f_tag {
  font-size: small;
  color: rgba(255, 255, 255, 0.7);
}

.f_link a {
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.8);
}

@media(max-width: 767.98px) {
  .folio {
    display: block;
  }

  .folio .f_shot {
    width: 100%;

  }

  .f_desc {
    width: 100%;
  }

}

@media(min-width: 768px) {
  .folio {
    display: flex;
  }

  .folio .f_shot {
    width: 60%;
  }

  .f_tag {
    margin-bottom: 1rem;
  }

  .f_desc {
    width: 40%;
    display: grid;
    align-content: start;
  }

  .f_details {
    background-color: #132B3C;
    padding: 1rem;
    border-radius: 0.25rem;
  }

  .folio:nth-child(odd) .f_shot {
    order: 2;
  }

  .folio:nth-child(odd) .f_desc {
    text-align: left;
    order: 1;
  }

  .folio:nth-child(even) .f_desc {
    text-align: right;
  }

  .folio:nth-child(even) .f_details {
    margin-left: -30%;
  }

  .folio:nth-child(odd) .f_details {
    margin-right: -30%;
  }
}
.p-rel {
    position: relative;


}

.about-intro {
    background-color: transparent;
}

.portrait {
    // background-image: url(../../images/clement.png);
    // transform: scaleX(-1);
    // background-size: contain;
    // background-position: center;
    // background-repeat: no-repeat;
    // background-clip: content-box;
    // position: relative;
}

.portrait img {
    transform: scaleX(-1);
    background-color: #EAE6E5;
}


.overlay-effect {
    position: absolute;
    bottom: 0;
    background-color: rgba(6, 32, 49, 0.3);
    transition: 0.5s ease;
}

.overlay-effect:hover {
    background-color: transparent;
    transition: 0.5s ease;
}

@media(max-width: 767.98px) {
    .about-intro {
        text-align: justify;
    }

    .portrait {
        text-align: center;
    }

    .portrait img {
        width: 90vw;
    }

    .overlay-effect {
        height: 90vw;
        width: 90vw;
        left: 50%;
        transform: translateX(-50%);
    }

}

@media(min-width: 768px) {
    .p-rel {
        min-height: 300px;
    }

    .about-intro {
        width: 50%;
    }

    .portrait {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
    }

    .portrait img {
        max-width: 300px;
    }

    .overlay-effect {
        height: 300px;
        width: 300px;
    }
}

.my-stack {
    margin: -7.5px;
}

.my-stack .col {
    background-color: rgba(255, 255, 255, 0.08);
    display: flex;
    align-items: center;
    padding: 2px 2.5px;
    margin: 7.5px;
    border-radius: 0.25rem;
    ;
}

.my-stack .col>img {
    margin: 5px;
    ;
}

.my-stack .col>img,
.my-stack .col>span {
    display: inline-flex;
}
// @import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap');


$accent-color: rgb(0, 255, 255);


body {
  color: #fff;
  margin: 0;
  background-color: #062031;
}

.my-accent-color {
  color: $accent-color;

  &:hover {
    color: desaturate($accent-color, 50%);
  }
}

.my-text-light {
  color: rgb(224, 224, 224);

  &:hover {
    color: $accent-color;
  }
}

.bg-dark-1 {
  background-color: #062031;
}

.bg-1 {
  background-color: rgba(75, 81, 93, 0.9);
}

.bg-2 {
  background-color: rgba(255, 255, 255, 0.05);
}

.navbar {
  width: 100vw;
}

.navbar.scrolling-navbar.top-nav-collapse {
  background-color: rgba(255, 255, 255, 0.05);
}

.brand {
  color: #fff;
  text-align: center;
  width: fit-content;
  display: flex;
  align-items: center;
  // font-family: 'Kanit', sans-serif;
  font-weight: 300;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 5px;

  &:hover {
    color: $accent-color;
  }

  &:before,
  &:after {
    // ●\25CF ◖\25D6◗\25D7❂\2742⊛\229B⊕\2295⊖\2296⊘\2298 ◍\25CD ◣\25E3 ◥\25E5 
    // ⑆\2446 ±\00B1 ⊂\2282 ∩\2229 †\2020 ✝\271D ⊤\22A4 ⊺\22A4 ＋\FF0B ﹢\FE62 ✢\2722
    // content: '\2742';// color: #d3d3d3;
    // font-size: 8px;
  }

  span {
    margin-left: 5px;
  }
}

.nav-item .nav-link.active {
  // background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  color: #0ff !important;
}

@media (max-width: 767.98px) {
  .collapse.show {
    height: calc(100vh - 74px);
  }

  .navbar-nav {
    margin: 25% auto 75% auto;
  }

  .nav-link {
    text-align: center;
  }
}

@media (min-width: 768px) {

  .hamburger-button__button,
  .hamburger-button__checkbox {
    display: none;
  }
}

h2 {
  font-weight: 600;
}

h3 {
  font-weight: 500;
}

h4 {
  font-weight: 400;
  color: #CDD2D6;
  color: rgba(255, 255, 255, 0.8);
}

.form-control {
  color: #fff;

  &:focus {
    color: #fff;
  }
}

.md-form {
  label {
    color: #fff;
  }

  .active {
    color: rgba(255, 255, 255, 0.8);
  }
}

.md-form input:not([type]):focus:not([readonly]),
.md-form input[type='text']:not(.browser-default):focus:not([readonly]),
.md-form input[type='email']:not(.browser-default):focus:not([readonly]),
.md-form input[type='tel']:not(.browser-default):focus:not([readonly]),
.md-form textarea.md-textarea:focus:not([readonly]) {
  border-bottom: 1px solid $accent-color;
  box-shadow: 0 1px 0 0 $accent-color;
}

.md-form input:not([type]):focus:not([readonly])+label,
.md-form input[type='text']:not(.browser-default):focus:not([readonly])+label,
.md-form input[type='email']:not(.browser-default):focus:not([readonly])+label,
.md-form input[type='tel']:not(.browser-default):focus:not([readonly])+label,
.md-form textarea.md-textarea:focus:not([readonly])+label {
  color: desaturate($accent-color, 20%);
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}


footer a {
  color: #fff;
  font-size: 1em;

  &:hover {
    color: $accent-color;
  }
}